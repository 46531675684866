<template>
  <div class="popupPage">
    <div id="popopSetting" class="section-wrapper" v-if="currentStep == 1">
      <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="popupForm">
        <form @submit.prevent="handleSubmit(onNext)">
          <div class="row">
            <div class="col-md-12">
              <span class="h2 dark-gray-span">Easily Create Your Own Pop Up For Your Website</span>
            </div>
            <div class="col-md-12 main-container">
              <span class="h4 gray-span">It is important during these difficult times that you inform your website visitor the status of your business. Use our pop-up generator to easily add a message to your website in three simple steps.</span>
            </div>
            <div class="col-md-12 main-container">
              <div class="row pt-3">
                <div class="col-md-12">
                  <textarea ref="editor"></textarea>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <a href="javascript:;" class="btn btn-primary" @click="preview">Click Here To Preview The Message</a>
                </div>
                <div class="col-md-12">
                  <button class="btn btn-success">Click Here To Continue</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <div id="userSetting" class="section-wrapper" v-if="currentStep == 2">
      <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="userForm">
        <form @submit.prevent="handleSubmit(onNext)">
          <div class="row">
            <div class="col-md-12">
              <span class="h2 dark-gray-span">Enter Your Contact Information Below So We Can Generate Your Code For you</span>
            </div>
            <div class="col-md-12 main-container">
              <div class="row">
                <div class="col-md-12 mt-3">
                  <text-input validate="required" :immediate="false" name="YourName" v-model="data.name" placeholder="Your Name..."/>
                </div>
                <div class="col-md-12">
                  <text-input validate="required" :immediate="false" name="YourWebsite" v-model="data.website" placeholder="Your Website Address..."/>
                </div>
                <div class="col-md-12">
                  <text-input type="email" validate="required" :immediate="false" name="YourEmail" v-model="data.email" placeholder="Your Email Address..."/>
                </div>
                <div class="col-md-12">
                  <text-input validate="required" :immediate="false" name="YourPhoneNumber" v-model="data.phone" placeholder="Your Phone Number..."/>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button class="btn btn-primary" :disabled="loading">
                    <span v-if="!loading">
                      Click Here To Get Your Code
                    </span>
                    <atom-spinner v-else slot="loading" :animation-duration="1500" :size="20" color="#fff" style="margin: auto" />
                  </button>
                </div>
                <div class="col-md-12">
                  <a href="javascript:;" class="btn btn-secondary" @click="onBack">Back</a>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <div id="payme-ifting" class="section-wrapper" v-if="currentStep == 3">
      <div class="row">
        <div class="col-md-12">
          <span class="h2 dark-gray-span">Pop-UP Generator Package</span>
        </div>
        <div class="col-md-12 main-container">
          <span class="h4 gray-span">Enter your payment details, click the pay now button and we will generate the code and email it to you.</span>
        </div>
        <div class="col-md-12 mt-2 main-container payment-container">
          <div class="row pt-3">
            <div class="col-md-12">
              <span class="h3 blue-span">Package Details </span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-7">
              <ul>
                <li>One Domain</li>
                <li>Unlimited Website Visitors</li>
              </ul>  
            </div>
            <div class="col-md-5">
              <span class="price-span-wrapper"><span class="price-span">${{sitedata.popupFee}}</span>One Time</span>
            </div>
          </div>
          <div class="row credit-card-form mt-3">
            <div class="col-md-12">
              <stripe-elements
                ref="stripeFormRef"
                :pk="sitedata.stripeKey || 'PK_'"
                :amount="sitedata.popupFee"
                @token="tokenCreated"
                @loading="stripeLoading = $event"
              >
              </stripe-elements>
              <button class="btn btn-primary btn-get-started" @click="payNow" :disabled="loading || stripeLoading">
                <span v-if="!loading && !stripeLoading">
                  Pay Now
                </span>
                <atom-spinner v-else slot="loading" :animation-duration="1500" :size="20" color="#fff" style="margin: auto;" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="scrip-ifing" class="section-wrapper" v-if="currentStep == 4">
      <div class="row">
        <div class="col-md-12">
          <span class="h2 dark-gray-span">Thank You For Your Order</span>
        </div>
        <div class="col-md-12 main-container">
          <span class="h4 gray-span">We have emailed you the script with instructions on how to install it. The script is also available below</span>
        </div>
        <div class="col-md-12 main-container">
          <div class="row pt-3">
            <div class="col-md-12">
              <span class="h5 gray-span">Copy the code below and paste it right before the closing <strong class="le-blue">&lt;/body&gt;</strong> tag or <strong class="le-blue">&lt;footer&gt;</strong> of your website code</span>
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-12">
              <textarea-input
                name="Message"
                label=""
                :disabled="true"
                :rows="5"
                v-model="modalScript"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <button
                class="btn btn-primary btn-copy"
                v-clipboard="modalScript"
                v-clipboard:success="onCopy"
                v-clipboard:error="onCopyError"
              >Click To Copy</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vuestic-modal
      :isOpen="isOpenModalPreview"
      @ok="closeModalPreview"
      @cancel="closeModalPreview"
      :large="true"
      :okShown="false"
      :cancelShown="false"
      :showHeader="false"
      :center="true"
      okText="Okay"
    >
      <div v-html="data.message"></div>
    </vuestic-modal>
  </div>
</template>

<script>
import VuesticColorPickerInput from '../../vuestic-theme/vuestic-components/vuestic-progress-bar/VuesticProgressBar';
import { StripeElements } from 'vue-stripe-checkout';
import { VueEditor } from "vue2-editor";

// froala-editor
import FroalaEditor from 'froala-editor'
import 'froala-editor/js/plugins.pkgd.min.js'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/froala_style.min.css'

export default {
  components: {
    StripeElements,
    VueEditor
  },
  data () {
    return {
      data: {},
      initData: {
        name: '',
        website: '',
        email: '',
        message: '',
        phone: '',
        domain: window.location.host,
      },
      stripeLoading: false,
      loading: false,
      modalScript: '',
      isOpenModalPreview: false,
      currentStep: 1,
      modalUserId: null,
      isPaid: null,
      editor: null,
      editorInitialized: false,
      editorConfig: {
        key: process.env.VUE_APP_FROALA_API_KEY,
        heightMin: 200,
        heightMax: 500,
        quickInsertEnabled: false,
        toolbarVisibleWithoutSelection: false,
        useClasses: false,
        enter: FroalaEditor.ENTER_BR,
        fontFamilySelection: true,
        fontSizeSelection: true,
        linkAlwaysBlank: true,
        linkStyles: {
          'fr-green': 'Green',
          'fr-strong': 'Thick',
          'underlineClass': 'Underline',
        },
        toolbarButtons: {
          'moreText': {
            'buttons': [
              'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'bold', 'italic', 'underline', 'alignLeft', 
              'alignCenter', 'alignRight', 'alignJustify', 'insertLink', 'insertImage', 'emoticons', 'formatUL', 'formatOL', 
              'lineHeight', 'strikeThrough', 'paragraphFormat', 'paragraphStyle', 'insertHR', 'outdent', 'indent', 'quote', 'undo', 'redo', 'fullscreen', 'html'
            ],
            'buttonsVisible': 10,
          },
        },
        emoticonsUseImage: false,
        requestHeaders: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        },
        imageUpload: true,
        imageUploadMethod: 'POST',
        imageUploadParam: 'image',
        imageUploadParams: {
          link: true,
        },
        imageUploadRemoteUrls: true,
        imageUploadURL: process.env.VUE_APP_BASE_API + 'api/v1/image',
        ...this.options,
        events: {
          initialized: () => {
            this.editor.html.set(this.data.message)
            this.initEvents()
          },
          blur: () => { 
            this.editor.selection.save();
          },
          destroy: () => { }
        },
      }
    }
  },

  mounted () {
    this.data = {...this.initData}
    this.isOpenModalPreview = false;
    this.isPaid = false;
    this.initEditor()
  },

  methods: {
    initEditor() {
      if (!this.editorInitialized) {
        this.editor = new FroalaEditor(this.$refs.editor, this.editorConfig)
        this.editorInitialized = true
      }
    },
    initEvents() {
      let _this = this
      _this.editor.events.on('contentChanged', function (e) {
        if (_this.data.message !== _this.editor.html.get()) {
          _this.data.message = _this.editor.html.get()
        }
      }, false);

    },
    onNext(){
      switch (this.currentStep) {
        case 1:
          if (!this.data.message.trim())
          {
            this.showToast("Please Fill Pop Up Content!");
          } else {
            this.currentStep = 2;
          }
          break;
        case 2:
          this.addModalUser();
          break;
        case 3:
          break;
      }
    },

    onBack() {
      if (this.currentStep > 1)
        this.currentStep--;
    },

    onCopy: function (e) {
        this.showToast("Script has been copied to your clipboard!");
    },
    onCopyError: function (e) {
      this.showToast("Failed to copy script to your clipboard", {
        icon: "fa-exclamation-triangle",
      });
    },

    tokenCreated (token) {
      this.loading = true
      this.token = token;
      const charge = {
        token_id: token.id,
        modal_user_id: this.modalUserId,
        website: this.data.website,
        message: this.data.message,
        domain: window.location.host,
      }
      this.sendTokenToServer(charge);
    },
    sendTokenToServer (charge) {
      this.loading = true
      this.$store.dispatch('agency/chargeModalUser', charge)
        .then((data) => {
          if (data.data.success)
          {
            this.isPaid = true;
            this.getScript();
          }
          this.loading = false
        }, () => {
          this.loading = false
        })
    },

    getScript() {
      this.loading = true
      const requestData = {
        modal_user_id: this.modalUserId,
        website: this.data.website,
        message: this.data.message,
        is_paid: this.isPaid
      }
      this.$store.dispatch('agency/getPopupScript', requestData)
        .then((data) => {
          this.loading = false          
          if (data.data.success)
          {
            this.modalScript = data.data.data
            this.currentStep = 4;
          }
        }, (error) => {
          this.loading = false
        })
    },

    payNow() {
      this.$refs.stripeFormRef.submit();
    },

    addModalUser() {
      this.loading = true
      this.$store.dispatch('agency/addModalUser', this.data)
        .then((data) => {
          this.loading = false          
          if (data.data.success)
          {
            this.modalUserId = data.data.data
            if (this.sitedata.popupPaymentEnabled){
              this.currentStep = 3;
            } else {
              this.getScript();
            }
          }
        }, (error) => {
          this.loading = false
        })
    },

    preview() {
      if (!this.data.message.trim())
      {
        this.showToast("Please Fill Pop Up Content!");
        return false;
      }
      this.isOpenModalPreview = true;
    },

    closeModalPreview() {
      this.isOpenModalPreview = false
    },
  },

  computed: {
    sitedata () {
      return this.$store.state.app.sitedata
    }
  },
}
</script>

<style lang="scss">
  .popupPage {
    padding: 20px;
    color: #555555;
    .blue-span {
      color: #366ca9;
    }
    .gray-span {
      color: #888888;
    }
    .dark-gray-span {
      color: #6f6f6f;
    }
    span {
      font-size: 17px;
    }

    .main-container {
      max-width: 800px;
      margin: auto;
      color: #777;
      &.payment-container {
        padding: 20px 40px;
        max-width: 700px;
        -webkit-box-shadow: 0px 0px 7px 3px rgba(122,122,122,0.3);
        -moz-box-shadow: 0px 0px 7px 3px rgba(122,122,122,0.3);
        box-shadow: 0px 0px 7px 3px rgba(122,122,122,0.3);
        @media (max-width: 480px) {
          padding: 20px 25px;
        }
        ul {
          font-weight: 500;
          li {
            font-size: 17px;
            line-height: 32px;
          }
        }
        .credit-card-form {
          padding: 20px;
          background-color: #efefef;
          border-radius: 5px;
          #payment-form {
            button[type="submit"] {
              display: none;
            }
          }
          @media (max-width: 480px) {
            padding: 20px 0px;
          }
        }
      }
    }

    .form-group input,
    .form-group textarea {
      height: auto;
      line-height: 1.5rem;
      border-radius: 3px;
      padding: 15px 10px;
      background-color: #f0f0f0;
      border: 1px solid #ccc;
      -webkit-box-shadow: inset 0px 0px 1px 1px rgba(122,122,122,0.1);
      -moz-box-shadow: inset 0px 0px 1px 1px rgba(122,122,122,0.1);
      box-shadow: inset 0px 0px 1px 1px rgba(122,122,122,0.1);
      @media (max-width: 480px) {
        padding: 10px 10px;
      }
    }
   
    .h2 {
      display: block;
      text-align: center;
      font-weight: 700!important;
      font-size: 39px;
      line-height: 45px;

      @media (max-width: 480px) {
        margin-bottom: 10px;
        font-size: 26px;
        line-height: 33px;
      }
    }
    .h3 {
      display: block;
      text-align: center;
      font-weight: 700!important;
      font-size: 30px;
      line-height: 33px;
      @media (max-width: 480px) {
        font-size: 26px;
        line-height: 33px;
      }
    }
    .h4 {
      display: block;
      text-align: center;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      @media (max-width: 480px) {
        font-size: 16px;
        line-height: 26px;
      }
    }

    .btn {
      margin-top: 20px;
      font-size: 21px;
      font-weight: 500;
      width: 100%;
      border-radius: 3px;
      padding: 15px;
      border: 1px solid #1f589a;
      -webkit-box-shadow: inset 0px 1px 1px 1px rgba(245, 245, 245, 0.1);
      -moz-box-shadow: inset 0px 1px 1px 1px rgba(245, 245, 245, 0.1);
      box-shadow: inset 0px 1px 1px 1px rgba(245, 245, 245, 0.1);
      text-transform: capitalize;
      letter-spacing: 0;
      &.btn-get-started {
        display: block;
        width: auto;
        min-width: 140px;
        margin-left: auto;
        margin-right: auto;
      }
      &.btn-copy{
        display: block;
        width: auto;
        margin: auto;
      }
      span {
        font-size: inherit;
      }
      @media (max-width: 480px) {
        font-size: 15px;
      }
    }
    .btn-success:hover {
      background-color: #5ebb61;
    }   

    .price-span-wrapper {
      display: block;
      text-align: center;
      font-size: 24px;
      color: #366ca9;
      font-weight: 500;
      .price-span {
        display: block;
        font-size: 54px;
        font-weight: 700!important;
        line-height: 54px;
      }
    }
    #scriptSetting {
      textarea {
        padding: 15px;
      }
    }
    .vuestic-modal .close-modal {
      @media (max-width: 480px) {
        right: -8px;
      }
    }
  }
</style>
